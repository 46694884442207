import React from 'react'
import ImageLazyLoading from './ImageLazyLoading';

const Logos = [ 
  require("../Assets/Images/logo-dark.png"),
  require("../Assets/Images/logonew.png"),
];


function Logo({type}){
  return (
    <div>
        <ImageLazyLoading height={60} source={Logos[type*1 >= 1 ? 1 : 0]} />     
    </div>
  )
}

export default Logo