import React from 'react';
import Slider from "react-slick";
import ProductCard from '../ProductCard';

const LinkHoot = "https://htmarkt.hightech-airer.pt/";
const Data  = [  
  {
    title:"Secadores de adsorção ADX-F-PDP com ponto de orvalho controlado com pré e pós-filtros instalados capacidade até 4,0 m3/min",
    image:"https://www.comprag.com/en/comprag/img_pop/06_adx.jpg",
    brand:"Comprag",
    price:null,
    link:LinkHoot+"product_details?item=667aaabed8db2c4d24dae228" 
  }, 
  {
    title:"Compressores de parafuso Série F com acionamento por correia, no tanque receptor e com secador refrigerado",
    image:"https://www.comprag.com/en/comprag/img_pop/20_fser.jpg",
    brand:"Comprag",
    price:null,
    link:LinkHoot+"product_details?item=66715a23a7f181ae1753794c" 
  }, 
  {
    title:"Secadores de adsorção modulares ADM-PDP com controle de ponto de orvalho sob pressão, pré e pós-filtros pré-instalados",
    image:"https://www.comprag.com/en/comprag/img_pop/05_adm.jpg",
    brand:"Comprag",
    price:null ,
    link:LinkHoot+"product_details?item=667aa64bd8db2c4d24dae146" 
  }, 
  {
    title:"SEPREMIUM 2 utilizável Separador de óleo/água",
    image:"https://ircxprd01-iroraclecloud.cec.ocp.oraclecloud.com/content/published/api/v1.1/assets/CONT06953F7319AF4B51A01B0826A67A2EEC/native/SEPREMIUM+2+SERVICEABLE.jpg?channelToken=c0f6af91fcd04ce99002a3c5038e2e8b",
    brand:"Jork",
    price:null,
    link:LinkHoot+"product_details?item=667d211cd8db2c4d24dafcd6" 
  }, 
  {
    title:"Sistema de alto vácuo com bomba de vácuo de parafuso seco em configuração ATEX    ",
    image:"https://bigiesse.it/wp-content/uploads/2015/02/T-ATEX_medio.png",
    brand:"Bigiessie",
    price:null,
    link:LinkHoot+"product_details?item=6685284172d1339d1e3bfcd7" 
  },  
];

function LatestProducts() {
  const settings = {dots: false, infinite: true,speed: 500, slidesToShow: 3.5,
   slidesToScroll: 3.5, autoplay: true, arrows:false,
   responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1.5,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
  };
  return (
    <div className='LatestProducts'>
      <div className="wrapper">
        <div className="content">
          <div className="description">
            <h1>Produtos na nossa Loja</h1>
            <p>
            Encontre aqui a nossa seleção de produtos que temos disponíveis  neste momento. A Hightech-Airer apresenta os produtos que necessita com a 
            qualidade que procura, disponíveis em loja. Compressores de várias capacidades e desempenhos para todos os 
            tipos de aplicações, trabalho profissional ou não.
            </p>
          </div>
          <div className="slider-container">
            <Slider {...settings}>
            {Data.map((item, index)=>{ 
                 return(
                 <ProductCard data={item} key={index} />
                );
              })}    
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LatestProducts