import React from 'react';
import Slider from "react-slick"; 
import ImageLazyLoading from './ImageLazyLoading';


const Data  = [  
  { 
    image:"https://hins.es/wp-content/uploads/2021/09/AIGNEP-1024x297.png",
    brand:"Aignep", 
  },
  { 
    image:"https://advancedair.com.au/wp-content/uploads/2018/07/ELGi_logo-1024x248.png",
    brand:"Elgi", 
  },
  { 
    image:"https://vacuumequipment.ru/upload/iblock/1be/1be0bd47509b6c52e12ab0ebe92f7bba.jpg",
    brand:"Comprag", 
  },
  { 
    image:"https://www.pure-gmbh.com/userdata/images/Jorc%20Logo.png",
    brand:"Jorc", 
  }, 
  { 
    image:"https://bigiesse.it/wp-content/uploads/2021/09/logo_BIGIESSE_50_LR.png",
    brand:"Bigiesse", 
  },
  { 
    image:"https://cdn-gfpgd.nitrocdn.com/qjFKPymBavMCPzwWHSZRFsafXvAVVBwv/assets/images/optimized/rev-4613d30/infinitypipeproducts.com/wp-content/uploads/2021/04/infinity-logo-header.png",
    brand:"Infinity", 
  },
];

function BrandsSections() {
  const settings = {dots: false, infinite: true,speed: 1500, slidesToShow: 5,
    slidesToScroll: 5, autoplay: true, arrows:false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      }, 
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='BrandsSections'>
      <div className="wrapper">
          <div className="content">
          <Slider {...settings}>
            {Data.map((item, index)=>{ 
                 return(
                  <div className={index === 2 ? "brd lg" : "brd"} key={index}>
                    <a href={`http://localhost:3001/products?brand=${item.name}`}></a>
                     <ImageLazyLoading source={item.image} />
                  </div>
                );
              })}    
            </Slider>
          </div>
      </div>
    </div>
  )
}

export default BrandsSections