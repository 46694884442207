import React from 'react'
import ImageLazyLoading from '../ImageLazyLoading'
import { Link } from 'react-router-dom';

const Img =  require("../../Assets/Images/escritorio.jpeg");

function ExcelencyHigh() {
  return (
    <div className='ExcelencyHigh'>
      <div className="wrapper">
          <div className="content">
                 <div className="text">
                     <h1>Como Trabalhamos ?</h1>
                     <p>
                     Com mais de dez anos de experiência, e um portefólio invejável de clientes satisfeitos, a Hightech-AIRER, localizada na Moita,
                      Setúbal, é uma empresa especializada em várias soluções industriais e domésticas.
                     </p>
                     <Link to="/about"><button className="btn bg-main text-light">Saiba mais</button></Link>
                 </div>
                 <div className="image">
                    <ImageLazyLoading source={Img}  height={700}  />
                 </div>
          </div>
      </div>
    </div>
  )
}

export default ExcelencyHigh