import React from 'react'
import ImageLazyLoading from './ImageLazyLoading'

function ProductCard({ data }) {

    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.slice(0, maxLength - 3) + '...';
        }
        return str;
      }

      
    return (
        <div className='ProductCard'>
            <div className="dt">
                <span>{data.brand}</span>
                <a href={data.link} target='_blank'>
                    <h3>{truncateString(data.title,  70)}</h3>
                </a>
                <h5>{data.price === null ? <span className="text-danger">Consultar preço</span> : data.price } </h5>
            </div>
            <div className="image bg-white bg-light">
                <a href={data.link} target='_blank'>
                    <ImageLazyLoading source={data.image} height={400} />
                </a>
            </div>
        </div>
    )
}

export default ProductCard