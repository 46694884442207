import React from 'react'
import ImageLazyLoading from './ImageLazyLoading'
import Form from 'react-bootstrap/Form';
import axios from "axios"
import { toast } from 'react-toastify';

function NewsLetterSection() { 
    const [Email, setEmail] = React.useState(null);
    const ReceiverEmail = "comercial@hightech-airer.pt";

    const NewsLetterSumbit = async(e)=>{
        e.preventDefault();
        try {
          if (Email !== null) {
              await axios.post("https://htmarketserver.vercel.app/"+"sendemailfornewsletter", {email_receiver:ReceiverEmail, email_sender:Email});
              setEmail(null);
              toast.success('Subscrição efectuada com sucesso !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
          } else { 
            toast.error('Lamentamos mas não foi possivel executar está ação !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
          } 
        } catch (error) {
            console.clear();
            console.log(error);
            toast.error('Lamentamos mas não foi possivel executar está ação !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    } 


  return (
    <div className='NewsLetterSection'>
        <div className="wrapper">
            <div className="content">
                <div className="wall">
                    <ImageLazyLoading source="https://areacooling.com/areacademy/wp-content/uploads/2022/05/compressors.jpg" height={400} />
                </div>
                <div className="formarea">
                    <h1>Aproveite as nossas promoções e saiba mais sobre os produtos em destaque.</h1>
                    <Form onSubmit={NewsLetterSumbit}>  
                       <Form.Control  onChange={(e)=>setEmail(e.target.value)} onPaste={(e)=>setEmail(e.target.value)} type="email" 
                       required placeholder="Escreva o seu email..." />
                       <button type='submit' className="btn bg-main text-light">Subscrever</button>
                    </Form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewsLetterSection