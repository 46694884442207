import React from 'react'
import Navbar_hight from './Components/Navbar_hight';
import HeaderHigh from './Components/HomeComponents/HeaderHigh';
import ServicesHigh from './Components/HomeComponents/ServicesHigh';
import WhyHigh from './Components/HomeComponents/WhyHigh';
import LatestWorks from './Components/HomeComponents/LatestWorks';
import ExcelencyHigh from './Components/HomeComponents/ExcelencyHigh';
import LatestProducts from './Components/HomeComponents/LatestProducts';
import BrandsSections from './Components/BrandsSections';
import FooterHight from './Components/FooterHight';
import NewsLetterSection from './Components/NewsLetterSection';
import ModalAlert from './Components/ModalAlert';

function Home() {
    document.title   =  "Hightech-Airer";
  return (
    <div> 
        <Navbar_hight active={1}  />
        <HeaderHigh />
        <ServicesHigh />
        <WhyHigh />
        <LatestWorks />
        <ExcelencyHigh />
        <LatestProducts />
        <BrandsSections />
        <NewsLetterSection />
        <FooterHight />
    </div>
  )
}

export default Home