import React from 'react'
import Navbar_hight from './Components/Navbar_hight';
import FooterHight from './Components/FooterHight';
import ServicesHigh from './Components/HomeComponents/ServicesHigh';
import ImageLazyLoading from './Components/ImageLazyLoading';
import HeaderServices from './Components/ServicesComponents/HeaderServices';
import WhatWeDoComponent from './Components/ServicesComponents/WhatWeDoComponent';
import LatestProducts from './Components/HomeComponents/LatestProducts';
import BrandsSections from './Components/BrandsSections';


const Img =  require("./Assets/Images/oficina.jpeg");

function Services() {
    document.title = "Hightech-Airer -  Serviços";

    return (
        <div className='Services'>
            <Navbar_hight active={4} />
            <div className="services-block">
                <HeaderServices />
                <ServicesHigh />
                <WhatWeDoComponent />
                <div className="service-information">
                    <div className="wrapper">
                        <div className="service-container">
                            <h1>Porque não existe ar de qualidade sem um tratamento eficiente.</h1>
                            <p>
                                Os modernos equipamentos de produção necessitam de Ar Comprimido nas suas mais diversas qualidades,
                                desde ar de sopro sem qualquer tratamento até ao completamente seco, isento de óleo e esterilizado.
                                As impurezas na nossa atmosfera são normalmente invisíveis a olho nu, no entanto podem impedir o normal funcionamento de
                                um sistema pneumático e equipamentos de consumo, tendo um efeito adverso na qualidade dos produtos.
                            </p>
                            <ImageLazyLoading source={Img} />
                            <p>
                                De modo a corresponder a todas as necessidades do cliente , a Hightech-Airer apresenta uma gama completa de sistemas de tratamento de Ar Comprimido, consistindo em:
                                Secadores de Refrigeração, Secadores de Adsorção ,Secadores de Membrana , Filtros ,Absorvedores de Carvão Ativado
                                ,Converter, e muito mais.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHight />
        </div>
    )
}

export default Services